<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="editUser">
      <b-form class="mt-2 auth-create-form" @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">
        <b-row>
          <b-col class="mb-2">
            <ImageDropzone ref="images" :files="files"></ImageDropzone>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <b-form-group label="Nombre" label-for="account-name">
              <b-form-input v-model="user.name" name="name" placeholder="Name" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Apellidos" label-for="account-surname">
              <b-form-input v-model="user.surname" placeholder="Surname" name="surname" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" name="Email" rules="required|email">
              <b-form-group label="E-mail" label-for="account-e-mail">
                <b-form-input v-model="user.email" name="email" placeholder="Email" :disabled="true" />
              </b-form-group>
              <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Teléfono" label-for="account-phone">
              <b-form-input v-model="user.phone" name="phone" placeholder="Phone" />
            </b-form-group>
          </b-col>

          <!-- roles -->
          <b-col sm="6">
            <validation-provider #default="{ errors }" :name="'Rol'" rules="required">
              <b-form-group :label="'Rol'" label-for="account-rols">
                <v-select v-model="user.roles" label="name_translation" :filterable="false" :searchable="false"
                  :options="rolesList" :disabled="true" placeholder="Selecciona un rol" />
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <b-button type="submit" variant="primary" class="mt-2 mr-1">
              Guardar
            </b-button>
            <b-button hidden v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" type="reset" class="mt-2"
              @click.prevent="resetForm">
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormCheckbox, BLink
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ImageDropzone,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
    BLink
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      files: [],
      user: this.generalData,
      profileFile: null,
      required,
      email,
      politica: false,
      disabledRole: false
    }
  },
  computed: {
    ...mapGetters({
      selectRoles: 'users/getRoles',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    rolesList () {
      return this.selectRoles.map((role) => {
        if (role.display_name) {
          role.name_translation = role.display_name[this.currentLanguage];
        }
        return role
      })
    }
  },
  async created() {
    let avatar = {
      name: 'Avatar',
      url: process.env.VUE_APP_IMAGE_PLACEHOLDER,
      urlDelete: `/users/delete_image/${this.generalData.id}`,
    }
    if (this.generalData.avatar !== null) {
      avatar.url = this.generalData.avatar
    }
    this.files = [avatar]
    this.user = this.generalData

    await this.getRoles()

  },
  methods: {
    ...mapActions({
      edit: 'users/edit',
      getRoles: 'users/getRoles',
    }),
    resetForm() {
      this.user = JSON.parse(JSON.stringify(this.generalData))
    },
    handleSubmit() {
      this.$refs.editUser.validate().then(success => {
        if (success) {
          const { id } = this.user
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, user: formData })
          } else {
            this.$refs.errorField.scrollIntoView({
              behavior: 'smooth',
            block: 'end', 
            });
        }
        }
      })
    },
    createFormData() {
      const data = this.$refs.images.getFormData()

      data.append('name', this.user.name)
      data.append('email', this.user.email)
      data.append('phone', this.user.phone)
      data.append('surname', this.user.surname)
      data.append('lang', this.user.lang)

      return data
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>